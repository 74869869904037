@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

body {
  margin: 0;
  background-color: #f5f5f5;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(./assets/sequoiaWallpaper.png);
  background-size: cover;
}
