.welcome-animation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  transition: opacity 1s;
  z-index: 9999;
  animation: blurFadeOut 2s forwards;

  img {
    border-radius: 1000px;
    max-width: 90%;
    width: 200px;
    box-shadow: 0 22px 70px 4px rgba(0, 0, 0, 0.56);
  }
}

@keyframes blurFadeOut {
  0% {
    backdrop-filter: blur(5px);
  }
  95% {
    backdrop-filter: blur(5px);
  }
  100% {
    backdrop-filter: blur(0);
    opacity: 0;
  }
}

.welcome-animation.fadeOut {
  opacity: 0;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10%,
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
