@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.tuto_container {
  border-radius: 20px;
  background-color: white;
  padding: 0 20px;
  height: 470px;
  width: 600px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  box-shadow: 0 22px 70px 4px rgba(0, 0, 0, 0.56);
  margin: auto;
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  user-select: none;

  @media screen and (max-width: 820px) {
    display: none;
  }

  .buttons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100px;
    margin-left: -10px;
    margin-top: 20px;

    .quitButton,
    .minimizeButton,
    .fullscreenButton {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 15px;
      width: 15px;
      border-radius: 1000px;
      transition: 0.14s ease-in-out;
      cursor: pointer;
    }

    .quitButton {
      background-color: red;

      &:hover {
        background-color: rgb(194, 0, 0);
      }

      &::before {
        content: "×";
        opacity: 0;
        color: black;
        font-weight: bold;
      }

      &:hover::before {
        opacity: 1;
      }
    }

    .minimizeButton {
      background-color: orange;

      &:hover {
        background-color: rgb(196, 127, 0);
      }

      &::before {
        content: "–";
        opacity: 0;
        color: black;
        font-weight: bold;
      }

      &:hover::before {
        opacity: 1;
      }
    }

    .fullscreenButton {
      background-color: rgb(0, 155, 0);

      &:hover {
        background-color: green;
      }

      &::before {
        content: "⛶";
        opacity: 0;
        color: black;
        font-weight: bold;
        font-size: 12px;
      }

      &:hover::before {
        opacity: 1;
      }
    }
  }
}
