@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.toolbar {
  -webkit-animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  height: 30px;
  background: rgba(83, 83, 83, 0.4);
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  display: flex;
  justify-content: space-between;
  user-select: none;
  color: white;

  a {
    text-decoration: none;
    color: white;
  }

  .menus {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-left: 20px;
    gap: 20px;
  }

  .icons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-right: 10px;
    gap: 20px;
    padding-top: 3px;

    p {
      margin: 0;
      padding-bottom: 3px;
    }

    @media screen and (max-width: 520px) {
      display: none;
    }
  }
}
