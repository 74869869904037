.page {
  overflow-y: scroll;
  max-height: calc(100% - 60px);
  scroll-behavior: smooth;
  &:hover {
    overflow-y: scroll;
  }
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 20px;
  }
  overflow-x: hidden;

  .resizeHandle {
    position: absolute;
    width: 15px;
    height: 15px;
    background: #ccc;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
    right: 5px;
    bottom: 5px;
    cursor: se-resize;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 50px;
    margin: auto;
    max-width: 90%;
    color: white;
    margin-top: 30px;

    .gridStack {
      display: grid;
      grid-template-columns: 1fr 1fr;

      @media screen and (max-width: 1200px) {
        grid-template-columns: 1fr;
      }
    }

    .row {
      display: flex;
      align-items: center;
      gap: 40px;

      @media screen and (max-width: 520px) {
        flex-direction: column;
      }
    }

    .rowText {
      display: flex;
      align-items: center;
      gap: 10px;
      max-width: 500px;
    }

    .demoMobile {
      display: none;

      @media screen and (max-width: 520px) {
        display: flex;
        flex-direction: column;
        text-align: center;

        img {
          max-width: 100%;
          border-radius: 10px;
        }
      }
    }

    .experience {
      .card-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 40px;

        @media screen and (max-width: 800px) {
          grid-template-columns: 1fr;
        }
      }

      .cardExperience {
        display: flex;
        flex-direction: column;
        align-items: left;
        border: 1px solid white;
        padding: 15px;
        border-radius: 14px;
        transition: 0.14s ease-in-out;

        &:hover {
          background-color: #49494988;
          transform: scale(1.01);
        }

        img {
          max-height: 100px;
          max-width: 130px;
          margin-bottom: 10px;
        }

        h4,
        p {
          margin: 5px;
        }
      }
    }

    @keyframes fadeInFromTop {
      0% {
        opacity: 0;
        transform: translateY(-20px);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .fade-in-from-top {
      animation-name: fadeInFromTop;
      animation-duration: 0.5s;
      animation-fill-mode: both;
    }

    .presentation {
      display: flex;
      align-items: center;
      gap: 40px;

      .round {
        min-height: 100px;
        min-width: 100px;
        border: 1px solid white;
        border-radius: 1000px;
        background-image: url(./../../assets/profil.png);
        background-size: cover;
      }
    }

    .iconesStack {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }
}
