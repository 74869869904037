@keyframes open-window {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.dock {
  width: auto;
  height: 60px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-animation: open-window 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: open-window 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  z-index: 1000000;

  .dock-container {
    padding: 3px;
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    background: rgba(83, 83, 83, 0.25);
    backdrop-filter: blur(13px);
    -webkit-backdrop-filter: blur(13px);
    border: 1px solid rgba(255, 255, 255, 0.18);

    .li-bin {
      margin-left: 20px;
      border-left: 1.5px solid rgba(255, 255, 255, 0.4);
      padding: 0px 10px;
    }
    .li-1 {
      &::after {
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.5);
        content: "";
        bottom: 2px;
      }
    }

    li {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      vertical-align: bottom;
      transition: 0.2s;
      transform-origin: 50% 100%;
      &:hover {
        margin: 0px 13px 0px 13px;
      }

      .name {
        position: absolute;
        top: -70px;
        background: rgba(0, 0, 0, 0.5);
        color: rgba(255, 255, 255, 0.9);
        height: 10px;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        visibility: hidden;
        &::after {
          content: "";
          position: absolute;
          bottom: -10px;
          width: 0;
          height: 0;
          backdrop-filter: blur(13px);
          -webkit-backdrop-filter: blur(13px);
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid rgba(0, 0, 0, 0.5);
        }
      }

      .ico {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.2s;

        &:hover {
          transform: scale(1.4) translateY(-15%);
        }
      }
      .ico-bin {
        width: 94% !important;
        height: 94% !important;
        object-fit: cover;
        transition: 0.2s;

        &:hover {
          margin-left: 10px;
        }
      }
    }
  }
}

@for $i from 1 through 15 {
  .li-#{$i}:hover {
    .name {
      visibility: visible !important;
    }
  }
}
