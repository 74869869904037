.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  backdrop-filter: blur(10px);
  max-height: 100vh;
  user-select: none;

  h2,
  p {
    color: white;
  }
}

.login-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  padding: 30px 40px;
}

.profile-image {
  box-shadow: 0 22px 70px 4px rgba(0, 0, 0, 0.56);
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.password-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #c4c4c4;
  border-radius: 12px;
  width: 100%;
  padding: 0 10px;
  margin-bottom: 20px;
}

input[type="password"] {
  flex: 1;
  border: none;
  color: white;
  font-size: larger;
  outline: none;
  padding: 10px;
  background: transparent;
}

button.login {
  position: relative;
  background-color: transparent;
  color: #ffffff;
  padding: 10px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  transition: 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: scale(1.2);
  }
}

.tooltip {
  position: absolute;
  bottom: 40px; // Ajustez selon votre besoin
  left: 50%;
  transform: translateX(-50%);
  background-color: #333; // Ou toute autre couleur de votre choix
  color: white;
  padding: 5px 10px;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  pointer-events: none; // Pour éviter tout interaction avec le tooltip
  animation: pulse 1.5s infinite;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
    transform: translateX(-50%);
  }
}

@keyframes pulse {
  0% {
    transform: translateX(-50%) scale(1);
  }
  50% {
    transform: translateX(-50%) scale(1.1);
  }
  100% {
    transform: translateX(-50%) scale(1);
  }
}